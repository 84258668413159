import React, { useContext, useState } from 'react'
import { Route, Redirect, Link, matchPath } from 'react-router-dom'
import { Header, List, Segment, Feed, Form, Rail, Label, Statistic, Button, Menu, Pagination, Grid, Message, Confirm, Divider, Icon, Popup } from 'semantic-ui-react';

import { AppContext } from './App'
import { ContactListUIContext } from './ContactList'
import Page from './Page'
import './ContactDetail.css'

import { submitRequest } from '../utilities/api'
import { BINDER_PROPS } from '../utilities/constants'
import { useContactListFilter } from '../utilities/helpers'
import CurrencyInput from 'react-currency-input-field';

// Wrapper Component for contact detail routes
// Note: caches the initial contact list the page is loaded with, for "prev/next" functionality
export function ContactDetailWrapper({ history, match, match: { params: { campaignId, contactListId, contactId } } }) {
  const [{ Campaigns: { [campaignId]: { ContactLists: { [contactListId]: currentContactList, [contactListId]: { Contacts: { [contactId]: currentContact } } } } } }] = useContext(AppContext)
  const [contactListUI] = useContext(ContactListUIContext)

  const initialContactList = useContactListFilter(currentContactList, contactListUI)
  const [nameQueue] = useState(initialContactList)
  const nameQueueIndex = nameQueue.findIndex(({ NameId }) => NameId === contactId)

  if (nameQueueIndex === -1) return <Redirect to={`/campaigns/${campaignId}/contact-lists/${contactListId}/contacts/${contactId}`} />
  if (!currentContact) return <Redirect to={`/campaigns/${campaignId}/contact-lists/${contactListId}`} />

  function mapContactToPaginationItem(before, { FirstName, MiddleName, LastName }, after) { const text = [before, FirstName, MiddleName, LastName, after].filter(Boolean).join(" "); return { content: text, "aria-label": text } }
  const prevContact = nameQueueIndex > 0 && nameQueue[nameQueueIndex - 1]
  const nextContact = nameQueueIndex < nameQueue.length - 1 && nameQueue[nameQueueIndex + 1]
  const prevPaginationItem = { position: `left`, fitted: true, ...(prevContact ? mapContactToPaginationItem(`« Previous:`, prevContact, ``) : { content: undefined, disabled: true }) }
  const nextPaginationItem = { position: `right`, fitted: true, ...(nextContact ? mapContactToPaginationItem(`Next:`, nextContact, `»`) : { content: undefined, disabled: true }) }
  function handlePageChange(event, { activePage }) { history.push(`/campaigns/${campaignId}/contact-lists/${contactListId}/contacts/${nameQueue[activePage - 1].NameId}`) }

  return <>
    <Route key={contactId} path={`/campaigns/${campaignId}/contact-lists/${contactListId}/contacts/${contactId}`} render={props => <ContactDetailPage {...props} match={{ ...match, params: { campaignId, contactListId, contactId } }} />} />
    <Pagination as="nav" size="huge" fluid secondary activePage={nameQueueIndex + 1} pageItem={null} prevItem={prevPaginationItem} nextItem={nextPaginationItem} ellipsisItem={null} firstItem={null} lastItem={null} boundaryRange={1} totalPages={nameQueue.length} onPageChange={handlePageChange} />
  </>
}

// Display Page Component for contact detail routes
function ContactDetailPage({ match: { path, url, params, params: { campaignId, contactListId, contactId } }, history }) {
  const [{ Caller, CallType, Campaigns: { [campaignId]: currentCampaign, [campaignId]: { ContactLists, ContactLists: {  [contactListId]: currentContactList, [contactListId]: { Contacts: { [contactId]: currentContact } } } } } }, setContext] = useContext(AppContext)
  const [UIState, setUIState] = useState({ Status: true, Lists: true, Income: true, CallsPledges: true, CallResult: false, Associations: true, })
  // I'm insane and I build dates like a maniac
  const [newCallPledgeState, setNewCallPledgeState] = useState(() => ({ NameId: contactId, Caller, CallType, CallDate: new Date(new Date().toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })).toJSON().replace(/T.*/, ``) }))
  const [callPledgeUIState, setCallPledgeUIState] = useState(() => ({ loading: false, success: false, error: false }))
  const [debouncedRequests, setDebouncedRequests] = useState({})

  const contactListOptions = Object.values(ContactLists)
    .filter(({ ListId }) => `${ListId}` !== contactListId)
    .map(({ ListId: option, ListName: text }) => ({ text: text, key: `${option}`, value: `${option}` }))
    .sort(({ text: a }, { text: b }) => a.localeCompare(b))

  const PageTitle = <><Link to={`/campaigns/${campaignId}/contact-lists/${contactListId}`} title={`Back to Contact List: ${currentContactList.ListName}`}>«</Link> Contact: {[currentContact.FirstName, currentContact.LastName].join(' ')}</>
  if (!matchPath(url, { path: path.replace(/:([^/]+)/gi, (match, paramKey) => params[paramKey]) })) return <Page loading title={PageTitle} />

  function handleContactUpdate(event, { name, value, checked }) {
    console.log(event, name, value, checked)
    const updatedValue = value ? value : typeof checked === "boolean" ? checked : ''
    setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts.${contactId}.${name}`, updatedValue)

    if (debouncedRequests[name]) clearTimeout(debouncedRequests[name])
    const scheduledRequest = setTimeout(async () => {
      const { CallsPledges, Income, Lists, ...submittableContactFields } = currentContact
      const updatedContact = { ...submittableContactFields, [name]: updatedValue }
      const response = await submitRequest({ path: `campaigns/${campaignId}/contacts/${contactId}`, method: `PUT`, body: JSON.stringify(updatedContact) })
      if (response.ok) console.log(response)
      else setContext(`error`, response)
    }, 1000)
    setDebouncedRequests({ ...debouncedRequests, [name]: scheduledRequest })
  }
  function handleCallPledgeFormUpdate(event, { name, value, checked }) {
    const updatedValue = value ? value : typeof checked === "boolean" ? checked : ''
    setNewCallPledgeState({ ...newCallPledgeState, [name]: updatedValue })
    if (name === `CallType` || name === `Caller`) setContext(name, updatedValue)
  }
  async function handleCallPledgeCREATE({ currentTarget }) {
    setCallPledgeUIState({ loading: true, success: false, error: false })
    const response = await submitRequest({ path: `campaigns/${campaignId}/contacts/${contactId}/calls-pledges/`, method: `POST`, body: JSON.stringify(newCallPledgeState) })
    if (response.ok) {
      const lowerCaseCallPledge = await response.json()
      const upperCaseCallPledge = Object.entries(Object.entries(lowerCaseCallPledge)[0][1]).reduce((upperCaseCallPledge, [lowercaseFieldName, value]) => ({ ...upperCaseCallPledge, [lowercaseFieldName.replace(/^(.)/, (match, p1) => p1.toUpperCase())]: value }), {})
      const updatedCallsPledges = [upperCaseCallPledge, ...currentContact.CallsPledges]
      setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts.${contactId}.CallsPledges`, updatedCallsPledges)
      handleContactUpdate(null, { name: `NextCalltimeDate`, value: newCallPledgeState.CallbackDate })
      setCallPledgeUIState({ loading: false, success: true, error: false })
    }
    else {
      setCallPledgeUIState({ loading: false, success: false, error: true })
      setContext(`error`, response)
    }
  }
  async function handleCallPledgeDELETE(CallPledgeId) {
    const response = await submitRequest({ path: `campaigns/${campaignId}/contacts/${contactId}/calls-pledges/${CallPledgeId}`, method: `DELETE` })
    if (response.ok || response.status === 404) {
      const updatedCallsPledges = currentContact.CallsPledges.filter(({ CallPledgeId: deletedId }) => CallPledgeId !== deletedId)
      setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts.${contactId}.CallsPledges`, updatedCallsPledges)
      console.log(response)
    } else {
      setContext(`error`, response)
    }
    setUIState({ ...UIState, CallsPledges: true })
  }
  async function handleRemoveListMember(ListId) {
    const response = await submitRequest({ path: `campaigns/${campaignId}/contacts/${contactId}/lists/${ListId}`, method: `DELETE` })
    if (response.ok || response.status === 404) {
      const updatedLists = currentContact.Lists.filter(({ ListId: deletedId }) => ListId !== deletedId)
      setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts.${contactId}.Lists`, updatedLists)
      console.log(response)
    } else {
      setContext(`error`, response)
    }
    setUIState({ ...UIState, Lists: true })
  }
  async function handleAddListMember(ListId) {
    const updatedLists = [...currentContact.Lists, ContactLists[ListId]]
    setContext(`Campaigns.${campaignId}.ContactLists.${contactListId}.Contacts.${contactId}.Lists`, updatedLists)
    setUIState({ ...UIState, Lists: true, ListPopup: false })
    const response = await submitRequest({ path: `campaigns/${campaignId}/contacts/${contactId}/lists/${ListId}`, method: `POST` })
    if (response.ok) {
      console.log(response)
    } else {
      setContext(`error`, response)
    }
  }
  function handleUIStateChange({ currentTarget }) { setUIState({ ...UIState, [currentTarget.getAttribute('name')]: !UIState[currentTarget.getAttribute('name')] }) }

  const UIStateIcons = Object.entries(UIState).reduce((UIStateIcons, [name, isOpen]) => ({ ...UIStateIcons, [name]: `caret ${isOpen ? `down` : `right`}` }), {})


  return <Page id="ContactDetailPage" title={PageTitle}>
    <Segment as="section" basic>
      <Form unstackable size="small">
        <Form.Group widths="3">
          <Form.Input name="FirstName" label="First Name" value={currentContact.FirstName || ''} onChange={handleContactUpdate} />
          <Form.Input name="MiddleName" label="Middle Name" value={currentContact.MiddleName || ''} onChange={handleContactUpdate} />
          <Form.Input name="LastName" label="Last Name" value={currentContact.LastName || ''} onChange={handleContactUpdate} />
        </Form.Group>
        <Form.Group widths="3">
          <Form.Input name="Salutation" label="Salutation" value={currentContact.Salutation || ''} onChange={handleContactUpdate} />
          <Form.Input name="Spouse" label="Spouse" value={currentContact.Spouse || ''} onChange={handleContactUpdate} />
          <Form.Select name="NameType" label="Name Type" options={currentCampaign.NAME_TYPE} value={currentContact.NameType || ''} onChange={handleContactUpdate} compact />
        </Form.Group>
        <Form.Group widths="3">
          <Form.Input name="BusinessName" label="Employer" value={currentContact.BusinessName || ''} onChange={handleContactUpdate} />
          <Form.Input name="Occupation" label="Occupation" value={currentContact.Occupation || ''} onChange={handleContactUpdate} />
          <Form.Field hidden={![currentContact.BusinessName, currentContact.BusinessCity, currentContact.BusinessState].filter(Boolean).length}>
            <label>{String.fromCharCode(8203)}</label>
            <a class="ui button fluid" target="blank" href={`https://www.google.com/search?q=${[currentContact.BusinessName, currentContact.BusinessCity, currentContact.BusinessState].filter(Boolean).join(`%20`)}`}>Search…</a>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="3">
          <Form.Input type="tel" name="HomePhone" label="Home Phone" value={currentContact.HomePhone || ''} onChange={handleContactUpdate} />
          <Form.Input type="tel" name="WorkPhone" label="Work Phone" value={currentContact.WorkPhone || ''} onChange={handleContactUpdate} />
          <Form.Input type="tel" name="OtherPhone" label="Other Phone" value={currentContact.OtherPhone || ''} onChange={handleContactUpdate} />
        </Form.Group>
        <Form.Input name="MailAddress" label="Mail Address" value={currentContact.MailAddress || ''} onChange={handleContactUpdate} />
        <Form.Input name="MailAddress2" label="Mail Address 2" value={currentContact.MailAddress2 || ''} onChange={handleContactUpdate} />
        <Form.Group widths="3">
          <Form.Input name="MailCity" label="Mail City" value={currentContact.MailCity || ''} onChange={handleContactUpdate} />
          <Form.Input name="MailState" label="Mail State" value={currentContact.MailState || ''} onChange={handleContactUpdate} />
          <Form.Input name="MailZip" type="zip" label="Mail Zip" value={currentContact.MailZip || ''} onChange={handleContactUpdate} />
        </Form.Group>
        <Form.Input type="email" name="Email" label="Email" value={currentContact.Email || ''} onChange={handleContactUpdate} />
        <Form.TextArea name="Notes" label="Notes" value={currentContact.Notes || ''} onChange={handleContactUpdate} />
      </Form>
      <Rail as="aside" close dividing position="right">
        <Segment as="section" size="small" color="orange">
          <Header as="header" name="Status" content="Status" dividing={UIState.Status} icon={UIStateIcons.Status} onClick={handleUIStateChange} />
          <Form size="small" hidden={!UIState.Status}>
            <Statistic.Group size="tiny" >
              <Statistic hidden={!currentContact.Binder} label={BINDER_PROPS[currentContact.Binder].title} value={<Button size="massive" className={BINDER_PROPS[currentContact.Binder].className} color={BINDER_PROPS[currentContact.Binder].color} icon={BINDER_PROPS[currentContact.Binder].icon} />} />
              <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>

                <CurrencyInput prefix="$" name="AskAmount" decimalsLimit={2}
                  value={parseFloat(currentContact.AskAmount || 0, 2)} onValueChange={(e) => {
                    handleContactUpdate(null, { name: `AskAmount`, value: e })
                  }} style={{
                    fontSize: '1.8em',
                    textAlign: 'center',
                    padding: '0em',
                    border: '0px',
                    width: '8rem',
                    margin: '0 0',
                  }} />
                <div style={{
                  fontWeight: '500',
                  width: '8rem',
                  textAlign: 'center',
                }}>Ask Amount</div>


              </div>
            </Statistic.Group>
            <Divider />
            <Form.Input type="date" name="NextCalltimeDate" label="To Call Date" value={currentContact.NextCalltimeDate ? currentContact.NextCalltimeDate.split('T')[0] : ''} onChange={handleContactUpdate} />
            <Form.Checkbox name="BadData" label="Bad Data?" checked={currentContact.BadData} onChange={handleContactUpdate} />
          </Form>
        </Segment>
        <Segment as="section" size="small" color="yellow">
          <Header as="header" name="Lists" content="Lists" dividing={UIState.Lists} icon={UIStateIcons.Lists} onClick={handleUIStateChange} />
          <Popup
          id="ListPopup"
            content={
              <div style={{
                maxHeight: '20em',
                overflowY: "scroll",
                overflowX: "hidden",
              }}>
                {contactListOptions.map(({ text, key }) => (
                  <div key={key} as="li" className="ListItemClickable" onClick={() => {
                    handleAddListMember(key)
                  }}>
                    <List.Content>
                      <List.Header>{text}</List.Header>
                    </List.Content>
                  </div>
                ))}
              </div>
            }
            position='bottom right'
            open={UIState.ListPopup}
            on="click"
            onOpen={() => setUIState({ ...UIState, ListPopup: true })}
            onClose={() => setUIState({ ...UIState, ListPopup: false })}
            trigger={
              <Button style={{
                height: '1.2em',
                width: '1.2em',
                padding: '0em',
                position: 'absolute',
                top: '1.1em',
                right: '2.3em',
              }} color="green" onClick={() => {
                console.log(currentContact)
              }}>+</Button>} />
          <List as="ul" relaxed hidden={!UIState.Lists}>
            {currentContact.Lists.length ? currentContact.Lists.map(({ ListId, ListName, ListDescription }) => (
              <List.Item key={ListId} className="item">

                <List.Content>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      <Icon name="address book" />
                      <div>
                        <List.Header>{ListName}</List.Header>
                        <List.Description>{ListDescription}</List.Description>
                      </div>
                    </div>

                    <Button color="red"
                      style={{
                        height: '1.2em',
                        width: '1.2em',
                        padding: '0em',
                      }}
                      onClick={() => {
                        // log current context
                        handleRemoveListMember(ListId)
                      }}>x</Button>
                  </div>
                </List.Content>
              </List.Item>
            )) : (
              <List.Item key="none" className="item">
                <List.Content>
                  <List.Header>None</List.Header>
                </List.Content>
              </List.Item>
            )}
          </List>
        </Segment>
        <Segment as="section" size="small" color="green">
          <Header as="header" name="Income" content="Income" dividing={UIState.Income} icon={UIStateIcons.Income} onClick={handleUIStateChange} />
          <Feed as="ul" hidden={!UIState.Income}>
            {currentContact.Income.length
              ? currentContact.Income.map(({ IncomeDate, IncomeAmount, IncomeProject }) =>
                <Feed.Event as="li" key={`${IncomeDate}.${IncomeAmount}.${IncomeProject}`} icon="dollar" content={<>
                  <Feed.Summary content={`Gave ${IncomeAmount.toLocaleString('en-US', { style: `currency`, currency: `USD` })}`} date={`(${new Date(IncomeDate).toLocaleDateString('en-US')})`} />
                  {IncomeProject && <Feed.Meta content={<>Project: <Label as="code" size="tiny" content={IncomeProject} /></>} />}
                </>} />)
              : <Feed.Event as="li" key="none" content="None" />
            }
          </Feed>
        </Segment>
        <Segment as="section" size="small" color="red">
          <Header as="header" name="Income" content="Associations" dividing={UIState.Associations} icon={UIStateIcons.Associations} onClick={handleUIStateChange} />
          <Feed as="ul" hidden={!UIState.Associations}>
            {currentContact.Associations.length
              ? currentContact.Associations.map(({ AssociationNameId, AssociationName, TotalCashContributions }) =>
                <Feed.Event as="li" key={AssociationNameId} icon="dollar" content={<Feed.Summary content={[AssociationName, TotalCashContributions && TotalCashContributions.toLocaleString('en-US', { style: `currency`, currency: `USD` })].filter(Boolean).join(`: `)} />} />)
              : <Feed.Event as="li" key="none" content="None" />
            }
          </Feed>
        </Segment>
        <Segment as="section" size="small" color="blue">
          <Header as="header" name="CallsPledges" content="Calls/Pledges" dividing={!!UIState.CallsPledges} icon={UIStateIcons.CallsPledges} onClick={handleUIStateChange} />
          <Feed as="ul" hidden={!UIState.CallsPledges}>
            {currentContact.CallsPledges.length
              ? currentContact.CallsPledges.map(({ CallPledgeId, AttnCandidate, AttnStaff, CallType, Caller, CallDate, CallbackDate, CallComplete, CallComments, PledgeAmount, PledgePromisedDate, SendPledgeLetter, PledgeFulfilled }) =>
                <Feed.Event as="li" key={CallPledgeId} icon="caret right" content={<>
                  <Button circular basic inverted compact size="tiny" floated="right" icon="ban" name="CallsPledges" value={CallPledgeId} onClick={() => setUIState({ ...UIState, CallsPledges: CallPledgeId })} />
                  <Confirm open={UIState.CallsPledges === CallPledgeId} name="CallsPledges" header={`Delete Call/Pledge #${CallPledgeId}`} value={true} onCancel={() => setUIState({ ...UIState, CallsPledges: true })} onConfirm={() => handleCallPledgeDELETE(CallPledgeId)} />
                  <Feed.Summary content={<><Feed.User as="b" content={Caller} />: {CallType}</>} date={`(${new Date(CallDate).toLocaleDateString('en-US')})`} />
                  {PledgeAmount && PledgePromisedDate && <Feed.Summary content={`Pledge: ${PledgeAmount.toLocaleString('en-US', { style: `currency`, currency: `USD` }).replace(/\.00/, ``)}`} date={`(${new Date(PledgePromisedDate).toLocaleDateString('en-US')})`} />}
                  <Feed.Meta content={CallComments} hidden={!CallComments} />
                </>} />)
              : <Feed.Event as="li" key="none" content="None" />
            }
          </Feed>
        </Segment>
      </Rail>
    </Segment>
    <Segment as="footer" basic className={UIState.CallResult ? `expanded` : `collapsed`} color={BINDER_PROPS[currentContact.Binder].color || `grey`}>
      <Header as="h2" name="CallResult" content="Call Result" dividing icon={UIStateIcons.CallResult} onClick={handleUIStateChange} />
      <Menu as="menu" name="CallResult" className="Binder" icon="labeled" secondary onClick={UIState.CallResult ? null : handleUIStateChange}>
        <Menu.Item as="li" aria-selected={currentContact.Binder === "TO_CALL"} content={<><Button name="Binder" value="TO_CALL" {...BINDER_PROPS["TO_CALL"]} onClick={handleContactUpdate} />{BINDER_PROPS["TO_CALL"].title}</>} />
        <Menu.Item as="li" aria-selected={currentContact.Binder === "CALENDAR"} content={<><Button name="Binder" value="CALENDAR" {...BINDER_PROPS["CALENDAR"]} onClick={handleContactUpdate} />{BINDER_PROPS["CALENDAR"].title}</>} />
        <Menu.Item as="li" aria-selected={currentContact.Binder === "LEFT_MESSAGE"} content={<><Button name="Binder" value="LEFT_MESSAGE" {...BINDER_PROPS["LEFT_MESSAGE"]} onClick={handleContactUpdate} />{BINDER_PROPS["LEFT_MESSAGE"].title}</>} />
        <Menu.Item as="li" aria-selected={currentContact.Binder === "COMMIT"} content={<><Button name="Binder" value="COMMIT" {...BINDER_PROPS["COMMIT"]} onClick={handleContactUpdate} />{BINDER_PROPS["COMMIT"].title}</>} />
        <Menu.Item as="li" aria-selected={currentContact.Binder === "RECEIVED"} content={<><Button name="Binder" value="RECEIVED" {...BINDER_PROPS["RECEIVED"]} onClick={handleContactUpdate} />{BINDER_PROPS["RECEIVED"].title}</>} />
        <Menu.Item as="li" aria-selected={currentContact.Binder === "NO"} content={<><Button name="Binder" value="NO" {...BINDER_PROPS["NO"]} onClick={handleContactUpdate} />{BINDER_PROPS["NO"].title}</>} />
      </Menu>
      <Grid columns="equal" as={Form} hidden={!UIState.CallResult} onSubmit={handleCallPledgeCREATE} {...callPledgeUIState}>
        <Grid.Column as="section">
          <Header as="h3" dividing icon="phone" content="Call Information:" />
          <Form.Group widths="2">
            <Form.Checkbox name="AttnStaff" label="Attn Staff" checked={newCallPledgeState.AttnStaff} onChange={handleCallPledgeFormUpdate} />
            <Form.Checkbox name="AttnCandidate" label="Attn Candidate" checked={newCallPledgeState.AttnCandidate} onChange={handleCallPledgeFormUpdate} />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Select name="CallType" label="Contact Type" options={currentCampaign.CALL_TYPE} value={newCallPledgeState.CallType} onChange={handleCallPledgeFormUpdate} compact required />
            <Form.Select name="Caller" label="Caller" options={currentCampaign.CALLER} value={newCallPledgeState.Caller || ''} onChange={handleCallPledgeFormUpdate} compact required />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input name="CallDate" label="Call Date" type="date" value={newCallPledgeState.CallDate ? newCallPledgeState.CallDate.split('T')[0] : ''} onChange={handleCallPledgeFormUpdate} required />
            <Form.Input name="CallbackDate" label="Callback Date" type="date" value={newCallPledgeState.CallbackDate ? newCallPledgeState.CallbackDate.split('T')[0] : ''} onChange={handleCallPledgeFormUpdate} />
          </Form.Group>
          <Form.Checkbox name="CallComplete" label="Call Complete" checked={newCallPledgeState.CallComplete} onChange={handleCallPledgeFormUpdate} />
          <Form.TextArea name="CallComments" label="Call Comments" value={newCallPledgeState.CallComments || ''} onChange={handleCallPledgeFormUpdate} />
        </Grid.Column>
        <Grid.Column as="section">
          <Header as="h3" dividing icon="usd" content="Pledge Information:" />
          <Form.Group widths="2">
            <Form.Checkbox name="SendPledgeLetter" label="Send Email/Letter" checked={newCallPledgeState.SendPledgeLetter} onChange={handleCallPledgeFormUpdate} />
            <Form.Checkbox name="PledgeFulfilled" label="Pledge Fulfilled" checked={newCallPledgeState.PledgeFulfilled} onChange={handleCallPledgeFormUpdate} />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input name="PledgeAmount" label="Pledge Amount" type="number" icon="usd" iconPosition="left" value={newCallPledgeState.PledgeAmount || ''} onChange={handleCallPledgeFormUpdate} required={!!(newCallPledgeState.PledgeAmount || newCallPledgeState.PledgePromisedDate)} />
            <Form.Input name="PledgePromisedDate" label="Date Promised or Asked" type="date" value={newCallPledgeState.PledgePromisedDate ? newCallPledgeState.PledgePromisedDate.split('T')[0] : ''} onChange={handleCallPledgeFormUpdate} required={!!(newCallPledgeState.PledgeAmount || newCallPledgeState.PledgePromisedDate)} />
          </Form.Group>
          <Message success content="Call results successfully recorded." />
          <Message error content="Call results were not successfully recorded." />
          <Form.Button content="Create New Call/Pledge" disabled={!newCallPledgeState.CallType || !newCallPledgeState.Caller || !newCallPledgeState.CallDate} />
        </Grid.Column>
      </Grid>
    </Segment>
  </Page >
}
