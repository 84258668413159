export const DEFAULT_CONTEXT = {}

export const DEFAULT_CALL_SESSION_CONTEXT = {
  sort: { column: `NextCalltimeDate`, order: `ascending` },
  filter: { Binder: {}, Search: ``, Due: true },
  sessionQueue: [],
}

export const API = {
  HOST: "https://calltimeapi.azurewebsites.net",
  HEADERS: {
    'Content-Type': `application/json`,
    'Accept-Language': `en-US`,
  },
  OPTIONS: {
    mode: `cors`,
    redirect: `follow`,
  },
}


export const SELECT_OPTIONS = {
  SORT_COLUMN: [
    { text: `Binder`, key: `Binder`, value: `Binder` },
    { text: `To Call Date`, key: `NextCalltimeDate`, value: `NextCalltimeDate` },
    { text: `Last Name`, key: `LastName`, value: `LastName` },
    { text: `First Name`, key: `FirstName`, value: `FirstName` },
    { text: `Occupation`, key: `Occupation`, value: `Occupation` },
    { text: `Business Name`, key: `BusinessName`, value: `BusinessName` },
    { text: `Work Phone`, key: `WorkPhone`, value: `WorkPhone` },
    { text: `Home Phone`, key: `HomePhone`, value: `HomePhone` },
    { text: `Other Phone`, key: `OtherPhone`, value: `OtherPhone` },
    { text: `Email`, key: `Email`, value: `Email` },
  ],
  SORT_ORDER: [
    { text: `Ascending`, key: `ascending`, value: `ascending` },
    { text: `Descending`, key: `descending`, value: `descending` },
  ],
}

export const BINDER_PROPS = {
  [null]: { title: ``, icon: `` },
  TO_CALL: { title: `To Call`, icon: `phone`, className: `white` },
  CALENDAR: { title: `Calendar`, icon: `calendar alternate outline`, color: `orange` },
  LEFT_MESSAGE: { title: `Left Message`, icon: `mail outline`, color: `yellow` },
  COMMIT: { title: `Commit`, icon: `handshake outline`, color: `blue` },
  RECEIVED: { title: `Received`, icon: `money bill alternate`, color: `green` },
  NO: { title: `No`, icon: `x`, color: `red` },
}
